<template>
  <div class="maintenance-container">
      <img src="./../assets/Logo_FacilImmo_300.jpg" alt="logo Facil'Imo" />
      <h1>Accès site en maintenance</h1>
      <div class="form-inspection" v-if="!$store.state.AUTH">
          <input type="password" v-model="pwd" @keyup="error = false" />
          <div class="btn" @click="login">Connexion</div>
          <div class="error" v-if="error">Mot de passe invalide</div>
      </div>
      <div class="btn logout-btn" @click="logout" v-else>Deconnexion</div>
  </div>
</template>

<script>
export default {
    name : 'Inspection',
    data() {
        return {
            pwd : null,
            error : false
        }
    },
    methods : {
        login() {
            if(this.pwd && this.pwd == 'synthes3d') {
                this.$store.commit('login')
                this.$router.push('/')
            }else {
                this.error = true
            }
        },
        logout() {
            this.$store.commit('logout')
        }
    }

}
</script>


<style lang="scss" scoped>
    

    .maintenance-container {
        min-height:100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .maintenance-container img {
        width:300px;
        height:auto;
        display: block;
        margin-bottom: 60px;
    }

    .maintenance-container h1 {
        margin:0;
        text-align: center;
        font-size:25px;
        color:$main-color;
        font-family: 'Montserrat', sans-serif;
    }

    .form-inspection {
        width: 400px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top:40px;
    }

    .form-inspection input {
        border:1px solid $grey;
        padding:10px 20px;
        width:200px;
        border-radius:10px;
        color:$text-color;
        margin-bottom:20px;
        font-family: 'Montserrat', sans-serif;        
    }

    .form-inspection input:focus {
        border-color:$main-color;
        outline: none;
    }

    .btn {
        padding:10px 20px;
        border-radius:10px;
        background:$main-color;
        color:#fff;
        font-family: 'Montserrat', sans-serif;
        transition:0.5s;
        cursor: pointer;
    }

    .btn:hover {
        background:$grey;
    }

    .error {
        padding:5px 20px;
        background:$error;
        color:#fff;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        font-size:12px;
        margin-top: 10px;
    }

    .logout-btn {
        margin-top:40px;
    }
</style>